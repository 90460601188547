import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { showSuccessToast, showErrorToast } from '../../../utils/toastUtils';
import '../Auth.css';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { requestVerifyToken } from '../../../store/actions/auth';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { clearError } from '../../../store/reducers/auth';
import '../Password.css'

interface ForgotPasswordState {
  emailText: string;
  showError: boolean;
  errorText: string;
}

const ForgotPasswordComponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const error = useSelector((state: RootState) => state.user?.error);

  const [state, setState] = useState<ForgotPasswordState>({
    emailText: '',
    showError: false,
    errorText: '',
  });

  const {
    emailText,
    showError,
    errorText
  } = state;

  useEffect(() => {
    dispatch(clearError());
  }, [dispatch]);

  const onChangeEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.length <= 30) {
      setState({ ...state, emailText: e.target.value.toString() });
    }
  };

  const handlePasswordResetRequest = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (emailText.length > 0) {
      await dispatch(requestVerifyToken(emailText))
        .then(() => {
          setState({ ...state, showError: false });
          showSuccessToast("비밀번호 재설정 메일이 발송되었습니다.");
        }).catch(() => {
          showErrorToast(error + '');
        });
    }
    if (emailText.length < 1) {
      setState({ ...state, showError: true, errorText: '*이메일을 입력해주세요' });
    }
  };

  return (
    <div className="forgot-password-container layoutContainer">
      <div className="forgot-password-wrapper">
        <h2>비밀번호 찾기</h2>
        <div className="text">
          <p >가입하신 이메일로 비밀번호 변경메일을 발송해드립니다.</p>
          {showError && <p className="error-message">{errorText}</p>}

        </div>
        <div className="input-group">
          <input
            type="email"
            value={emailText}
            onChange={onChangeEmailInput}
            placeholder="이메일"
            className={showError ? 'input-error' : ''}
          />
        </div>
        <button onClick={handlePasswordResetRequest} className="reset-password-button MainBtncolor">
          비밀번호 재설정하기
        </button>
      </div>
    </div>
  );
};

export default ForgotPasswordComponent;
