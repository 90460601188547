import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Header.css';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { logout } from '../store/reducers/auth';

const Header: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem('token');
    navigate('/');
  };


  const location = useLocation();

  return (
    <header className="header">
      <div className="headerContainer">
        <div className="logo">
          <Link to="/">DTX</Link>
          <Link to="/">노인성질환 연구대상자 전자동의서</Link>
        </div>

        <nav className="nav">
          <ul className="nav-list">
            <li className="nav-item">
              {user?.isAuthenticated ? (
                user.userType === 'staff' ? (
                  <div className='nav-container'>

                    <div className="nav-menu">
                      <Link to="/patientList" className={`nav-link ${location.pathname === '/patientList' ? 'Active' : ''}`}>환자 관리</Link>

                      <Link to="/schedule-management" className={`nav-link ${location.pathname === '/schedule-management' ? 'Active' : ''}`}>스케줄 관리</Link>
                    </div>
                    <button onClick={handleLogout} className="nav-link logout-button">Logout</button>
                  </div>
                ) : (
                  <div className='nav-container'>
                    <div className="nav-menu">

                      <Link to="/appointment" className={`nav-link ${location.pathname === '/appointment' ? 'Active' : ''}`}>진료 예약</Link>

                      <Link to="/medical-records" className={`nav-link ${location.pathname === '/medical-records' ? 'Active' : ''}`}>의료 기록</Link>

                    </div>
                    <button onClick={handleLogout} className="nav-link logout-button">Logout</button>
                  </div>
                )
              ) : (
                null
              )}
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;