import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../store/store';
import { useSelector } from 'react-redux';
import { showSuccessToast } from '../utils/toastUtils';
import './MainLayout.css'

const MainLayout: React.FC = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user);

  const navigateToAuth = (userType: 'staff' | 'patient') => {
    if (userType === 'patient') {
      navigate('/patient/login');
    } else {
      navigate('/staff/login');
    }
  };
  useEffect(() => {
    if (location.state?.successMessage) {
      showSuccessToast(location.state.successMessage);
    }
  }, [location.state]);

  return (
    <div className='main' style={{ textAlign: 'center'}}>
      {user.user?.isAuthenticated ? (
        <h2>{user.user?.name}님, 환영합니다!</h2>
      ) : (
        <>
          <h2>사용하실 <br /> 로그인을 선택해주세요.</h2>
         <div className="btn_container">
          <button className='button_styleP' style={{ padding: '10px 20px' }} onClick={() => navigateToAuth('patient')}>
              환자용 <br /> 로그인/회원가입
            </button>
            <button  className='button_styleS' style={{ padding: '10px 20px' }} onClick={() => navigateToAuth('staff')}>
              의료진용 <br /> 로그인/회원가입
            </button>
         </div>
        </>
      )}
    </div>
  );
};

export default MainLayout;